import React from 'react'
import Error from 'next/error'
import { Amplitude, LogOnMount } from '@amplitude/react-amplitude'

import { urls } from '../src/utils/config'
import Nav from '../src/components/Nav'
import Footer from '../src/components/Footer'

export default function NotFound(): JSX.Element {
  return (
    <Amplitude
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      eventProperties={(inheritedProps: { page: any }) => ({
        ...inheritedProps,
        page: {
          ...inheritedProps.page,
          name: urls.notFound,
        },
      })}
    >
      {(): JSX.Element => (
        <>
          <LogOnMount eventType="page-displayed" />
          <Nav dark />
          <Error statusCode={404} /> <Footer />
        </>
      )}
    </Amplitude>
  )
}
